<template>
  <div class="container" v-show="isShow">
    <div id="reader"></div>
  </div>
</template>
<script setup>
import { reactive, onMounted, onUnmounted, ref } from "vue";
// import { showToast  } from 'vant';
import { Html5Qrcode } from "html5-qrcode";
let html5QrCode = ref(null);
onMounted(() => {
  getCameras();
});

const isShow = ref(false);

const onSearchOrder = () => {
  console.log("在运运单");
  router.push("order");
};
onUnmounted(() => {
  stop();
});
const getCameras = () => {
  Html5Qrcode.getCameras()
    .then((devices) => {
      if (devices && devices.length) {
        isShow.value = true;
        html5QrCode = new Html5Qrcode("reader");
        // start开始扫描
        start();
      }
    })
    .catch((err) => {
      // handle err
      console.log("获取设备信息失败", err); // 获取设备信息失败
      // showToast('获取设备信息失败')
    });
};
const start = () => {
  html5QrCode
    .start(
      { facingMode: "environment" },
      {
        fps: 20, // 设置每秒多少帧
        qrbox: { width: 250, height: 250 }, // 设置取景范围
        // scannable, rest shaded.
      },
      (decodedText, decodedResult) => {
        alert("扫码结果" + decodedText);
      },
      (errorMessage) => {
        // parse error, ideally ignore it. For example:
        // console.log(`QR Code no longer in front of camera.`);
        console.log("暂无额扫描结果", errorMessage);
      }
    )
    .catch((err) => {
      // Start failed, handle it. For example,
      console.log(`Unable to start scanning, error: ${err}`);
    });
};
const stop = () => {
  if (devicesInfo.value) {
    html5QrCode
      .stop()
      .then((ignore) => {
        // QR Code scanning is stopped.
        console.log("QR Code scanning stopped.", ignore);
      })
      .catch((err) => {
        // Stop failed, handle it.
        console.log("Unable to stop scanning.", err);
      });
  }
};
</script>
<style lang="scss" scoped>
.container {
  position: relative;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100%;
  background: rgba($color: #000000, $alpha: 0.48);
  z-index: 999;
}
#reader {
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
</style>
